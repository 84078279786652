import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Calender = () => {
    const tileContent = ({ date, view }: { date: any, view: any }) => {
        // Kontextabhängige Anpassung des Kachelinhalts
        if (view === 'month') {
            return (
                <div id='calender-tile'>Test</div>

            );
        }
    };


    return (
        <div className="calender">
            <div id='container'>
                <Calendar
                    tileContent={tileContent}
                />
            </div>
        </div>
    );
}

export default Calender;
