import React from 'react';
import './App.css';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useNavigate
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Logoff from "./pages/Logoff";
import Logon from "./pages/Logon";
import Config from "./pages/Config";
import Calender from './pages/Calender';

import { UserProvider } from './auth/UserContext';
import { useUserContext } from './auth/UserContext';

import "./styles/style.scss"
import "./styles/navbar.scss"
import "./styles/logoff.scss"
import "./styles/logon.scss"
import "./styles/calender.scss"

const Layout = () => {
  const { isLoggedIn, isAdmin } = useUserContext();

  return (
    <>
      <Header />
      <Navbar />
      {isLoggedIn ? <Outlet /> : <Logoff />}
    </>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Logon />
      },
      {
        path: "/configView",
        element: <Config />
      },
      {
        path: "/calenderView",
        element: <Calender />
      },
    ]
  },
]);


function App() {
  return (
    <div className="app">
      <div className="container">
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </div>
    </div>
  );
}

export default App;
